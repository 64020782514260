<template>
  <div>
  <formNewUser />
</div>
</template>
<script>
import formNewUser from "../components/FormNewUser.vue";
export default {
  components: { formNewUser },
};
</script>