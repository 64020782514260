<template>
  <v-container  class="elevation-4">
    <h1 class="primary--text">Aqui é onde você poderá construir a sua solução</h1>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    userName: '',
    userEmail: '',
    userId: '',
  }),

};
</script>